import { setStepInprogress, setStepIsLoading, getCandidate, updateCandidate, setCriticalError, resetCandidate, generatePDFCandidate, getCandidates, initCandidateByMail, getCandidatePrices, updateCandidatePrices, checkCandidatePrices, setFilterCandidatePrices, resetCandidatePrices, saveSubmittedInputs } from './actions'
import { upload } from "../general/operations";
import { formSupplierSelectors } from "state/ducks/formSupplier";

const candidatePhase = process.env.REACT_APP_CANDIDATE_PHASE; // string

const initCandidate = (email) => (dispatch, getState) => {
  dispatch(setStepInprogress(0))
  return dispatch(initCandidateByMail(email, candidatePhase))
}

const loadCandidateByToken = (token) => (dispatch, getState) => {
  return dispatch(getCandidates({token:token}))
  .then((result) => {
    if (result?.data && result.data.length > 0) {
      dispatch(setStepInprogress(0))
      return dispatch(loadCandidate(result.data[0].id))
    }
    return Promise.reject("Ce code ne correspond à aucune candidature");
  });
}

const loadCandidate = (candidateId) => (dispatch, getState) => {
  if (candidateId) {

    return dispatch(getCandidate(candidateId))
    .then((result) => {
      // Impossible d'être aux autres étapes si la candidature est close
      if (result?.data?.closeAndGenerate && result.data.closeAndGenerate === true) {
        return dispatch(setStepInprogress(11));
      }
    })
    .catch(error => {
      if (error.response) {
        if (error?.response?.status === 404) {
          return dispatch(setCriticalError({type:'candidateNotFound'}))
        }
      }
      return dispatch(setCriticalError({type:'unknowError'}))
    });
  }
}

const setStep = (step) => (dispatch, getState) => {
  return dispatch(setStepInprogress(step));
}

const handleNextStep = (values, candidateId) => (dispatch, getState) => {
  // Que 11 steps
  if (getState().formSupplier.stepInprogress < 11) {
    return dispatch(setStepInprogress(getState().formSupplier.stepInprogress));
  }
}

const setStep2 = (values, candidateId) => (dispatch, getState) => {
  let data = {
    lot                     :null,
    departmentInterlocutors :[]
  }
  data.lot = values?.technicalCategory ? `api/lots/${values.technicalCategory}` : null;

  let isRegion = values?.regions && values.regions.length > 0 ? true : false;
  let departmentInterlocutorIndex = 0;
  if (isRegion) {
    values.regions.forEach((region, i) => {
      data.departmentInterlocutors.push({
        id: values?.departmentInterlocutorIds[departmentInterlocutorIndex] ? `api/department_interlocutors/${values.departmentInterlocutorIds[departmentInterlocutorIndex]}` : null,
        secondary: false,
        region:`api/regions/${region}`
      });
      departmentInterlocutorIndex++;
    });
    values.fruitlessnessRegions.forEach((region, i) => {
      data.departmentInterlocutors.push({
        id: values?.departmentInterlocutorIds[departmentInterlocutorIndex] ? `api/department_interlocutors/${values.departmentInterlocutorIds[departmentInterlocutorIndex]}` : null,
        secondary: true,
        region:`api/regions/${region}`
      });
      departmentInterlocutorIndex++;
    });
  }
  else {
    values.departments.forEach((department, i) => {
      data.departmentInterlocutors.push({
        id: values?.departmentInterlocutorIds[departmentInterlocutorIndex] ? `api/department_interlocutors/${values.departmentInterlocutorIds[departmentInterlocutorIndex]}` : null,
        secondary: false,
        department:`api/departments/${department}`
      });
      departmentInterlocutorIndex++;
    });
    values.fruitlessnessLots.forEach((department, i) => {
      data.departmentInterlocutors.push({
        id: values?.departmentInterlocutorIds[departmentInterlocutorIndex] ? `api/department_interlocutors/${values.departmentInterlocutorIds[departmentInterlocutorIndex]}` : null,
        secondary: true,
        department:`api/departments/${department}`
      });
      departmentInterlocutorIndex++;
    });
  }

  return dispatch(updateCandidate({...data, step: 2}, candidateId)).then(
    success => {
      return dispatch(handleNextStep());
    }
  );
}

const setStep3 = (values, candidateId) => (dispatch, getState) => {

    /**
     * Upload tous les médias de l'étape
     */
    const uploadFiles = async (contractor) => {
      let filesUri = {
      }
      if (contractor.logoUpload instanceof File) {
        filesUri.logo = await upload(contractor.logoUpload, dispatch);
      }
      if (contractor.companyInsuranceFileUpload instanceof File) {
        filesUri.companyInsuranceFile = await upload(contractor.companyInsuranceFileUpload, dispatch);
      }
      if (contractor.authorizationMandataryFileUpload instanceof File) {
        filesUri.authorizationMandataryFile = await upload(contractor.authorizationMandataryFileUpload, dispatch);
      }
      if (contractor.legalRedressFileUpload instanceof File) {
        filesUri.legalRedressFile = await upload(contractor.legalRedressFileUpload, dispatch);
      }
      if (contractor.fiscalRegularityFileUpload instanceof File) {
        filesUri.fiscalRegularityFile = await upload(contractor.fiscalRegularityFileUpload, dispatch);
      }
      if (contractor.companyInsuranceFileUpload instanceof File) {
        filesUri.companyInsuranceFile = await upload(contractor.companyInsuranceFileUpload, dispatch);
      }
      return filesUri;
    }

    const valuesToContractors = async (values) => {
        let contractors = [];
        for (const contractor of values.contractors) {
          let thisContractor = {};
          const files = await uploadFiles(contractor);
          if (files?.logo) {
            thisContractor.logo = files.logo;
          }
          if (files?.authorizationMandataryFile) {
            thisContractor.authorizationMandataryFile = files.authorizationMandataryFile;
          }
          if (files?.legalRedressFile) {
            thisContractor.legalRedressFile = files.legalRedressFile;
          }
          if (files?.fiscalRegularityFile) {
            thisContractor.fiscalRegularityFile = files.fiscalRegularityFile;
          }
          if (files?.companyInsuranceFile) {
            thisContractor.companyInsuranceFile = files.companyInsuranceFile;
          }

          thisContractor = {...thisContractor,
            id: contractor?.id ? `api/contractors/${contractor.id}` : null,
            organismName: contractor?.organization ? contractor.organization : null,
            organismType: contractor?.typeOrganization ? contractor.typeOrganization : null,
            organismCapital: contractor?.capital ? contractor.capital : null,
            representativeFirstname: contractor?.firstname ? contractor.firstname : null,
            representativeLastname: contractor?.lastname ? contractor.lastname : null,
            representativeFunction: contractor?.function ? contractor.function : null,
            siret: contractor?.siret ? contractor.siret : null,
            rcs: contractor?.rcs ? contractor.rcs : null,
            phone: contractor?.phone ? contractor.phone : null,
            email: contractor?.email ? contractor.email : null,
            companyInsuranceName: contractor?.companyInsuranceName ? contractor.companyInsuranceName : null,
            insurancePolicyNumber: contractor?.insurancePolicyNumber ? contractor.insurancePolicyNumber : null,
            companyInsuranceText: contractor?.companyInsuranceText ? contractor.companyInsuranceText : null,
            address: {
              id:contractor?.addressId ? `api/addresses/${contractor.addressId}` : null,
              streetNumber: contractor?.addressNumber ? contractor.addressNumber : null,
              street: contractor?.addressStreet ? contractor.addressStreet : null,
              postal: contractor?.addressPostalCode ? contractor.addressPostalCode : null,
              city: contractor?.addressCity ? contractor.addressCity : null,
            },
            fiscalRegularityText: contractor?.fiscalRegularityText ? contractor.fiscalRegularityText : null,
            insurance10Certificate: contractor?.is10insuranceCertificate ? contractor.is10insuranceCertificate : null,
            legalRedress: contractor?.isLegalRedress ? contractor.isLegalRedress : null,
            legalRedressText: contractor?.legalRedressText ? contractor.legalRedressText : null,
            swornStatement: contractor?.isSwornStatement ? contractor.isSwornStatement : null,
            authorizationMandataryText: contractor?.authorizationMandataryText ? contractor.authorizationMandataryText : null,
          };
          contractors.push(thisContractor);
        }
        return contractors;
    }

    return valuesToContractors(values)
      .then((response) => {
        let data = {};

        data = {
          typeCandidate: values?.typeCandidate ? values.typeCandidate : null,
          typeBusinessGroup: values?.typeBusinessGroup ? values.typeBusinessGroup : null,
          mandatarySolidarity:
          values?.mandatarySolidarity && values.mandatarySolidarity === 'yes' ?
          true
          :
          values?.mandatarySolidarity && values.mandatarySolidarity === 'no' ?
          false
          :
          null,
          contractors: response
        }

        return dispatch(updateCandidate({...data, step: 3}, candidateId)).then(
          success => {
            return dispatch(handleNextStep());
          }
        );
      })
      .catch((err) => {
        console.log((err))
      })
    ;
}

const setStep4 = (values, candidateId) => async (dispatch, getState) => {

  let data = {};
  if (values?.bankDetails?.ribUpload) {
    let rib = await upload(values.bankDetails.ribUpload, dispatch);
    data.rib = rib;
  }

  data = {...data,
    departmentInterlocutors: [],
    iban: values?.bankDetails?.iban ? values.bankDetails.iban : null,
    bic: values?.bankDetails?.bic ? values.bankDetails.bic : null,
    remunerationInterlocutor:{
      'id': values?.contactDetailsCentralis?.id ? `api/interlocutors/${values.contactDetailsCentralis.id}` : null,
      'name': values?.contactDetailsCentralis?.lastname ? values.contactDetailsCentralis.lastname : null,
      'function': values?.contactDetailsCentralis?.function ? values.contactDetailsCentralis.function : null,
      'email': values?.contactDetailsCentralis?.email ? values.contactDetailsCentralis.email : null,
      'phone': values?.contactDetailsCentralis?.phone ? values.contactDetailsCentralis.phone : null
    }
  }

  values.contactDetailsBuyers.forEach((contactDetailsBuyer, i) => {
    data.departmentInterlocutors.push({
      'id': contactDetailsBuyer?.id ? `api/department_interlocutors/${contactDetailsBuyer.id}` : null,
      'contractor': contactDetailsBuyer?.company ? `api/contractors/${contactDetailsBuyer.company}` : null,
      'interlocutor': {
        'id': contactDetailsBuyer?.interlocutorId ? `api/interlocutors/${contactDetailsBuyer.interlocutorId}` : null,
        'name': contactDetailsBuyer?.lastname ? contactDetailsBuyer.lastname : null,
        'function': contactDetailsBuyer?.function ? contactDetailsBuyer.function : null,
        'email': contactDetailsBuyer?.email ? contactDetailsBuyer.email : null,
        'phone': contactDetailsBuyer?.phone ? contactDetailsBuyer.phone : null
      },
      'emailDuplicate': contactDetailsBuyer?.emailDuplicate ? contactDetailsBuyer.emailDuplicate : null
    });
  });
  values.contactDetailsBuyersSecondary.forEach((contactDetailsBuyer, i) => {
    data.departmentInterlocutors.push({
      'id': contactDetailsBuyer?.id ? `api/department_interlocutors/${contactDetailsBuyer.id}` : null
    });
  });

  return dispatch(updateCandidate({...data, step: 4}, candidateId)).then(
    success => {
      return dispatch(handleNextStep());
    }
  );
}

const setStep5 = (values, candidateId) => (dispatch, getState) => {

  const valuesToContractors = async (values) => {
    let currentContractors = formSupplierSelectors.contractorsSelector(getState());

    return await Promise.all(values.activitiesAptitudeDetails.map(async (activitiesAptitudeDetail, i) => {
      let thisContractor = {};
      if (activitiesAptitudeDetail?.proofFileUpload) {
        thisContractor.qualificationCertificateFile = await upload(activitiesAptitudeDetail.proofFileUpload, dispatch);
      }
      thisContractor = {...thisContractor,
        qualificationCertificateName: activitiesAptitudeDetail?.name ? activitiesAptitudeDetail.name : null,
        qualificationCertificateText: activitiesAptitudeDetail?.proofText ? activitiesAptitudeDetail.proofText : null,
        id:`api/contractors/${currentContractors[i].id}`
      }
      return thisContractor;
    }));
  }

  return valuesToContractors(values)
  .then((response) => {
    let data = {
      contractors: response
    }

    return dispatch(updateCandidate({...data, step: 5}, candidateId)).then(
      success => {
        return dispatch(handleNextStep());
      }
    );
  })
  .catch((err) => {
    console.log((err))
  });
}

const setStep6 = (values, candidateId) => (dispatch, getState) => {
  let currentContractors = formSupplierSelectors.contractorsSelector(getState());
  let data = {
    contractors: []
  };
  values.economicCapacity.forEach((item, i) => {
    data.contractors.push({
      revenueY1: item?.y1Revenue ? item.y1Revenue : null,
      revenuePercentY1: item?.y1PartOfRevenue ? item.y1PartOfRevenue : null,
      revenueY2: item?.y2Revenue ? item.y2Revenue : null,
      revenuePercentY2: item?.y2PartOfRevenue ? item.y2PartOfRevenue : null,
      revenueY3: item?.y3Revenue ? item.y3Revenue : null,
      revenuePercentY3: item?.y3PartOfRevenue ? item.y3PartOfRevenue : null,
      id:`api/contractors/${currentContractors[i].id}`
    });
  });

  return dispatch(updateCandidate({...data, step: 6}, candidateId)).then(
    success => {
      return dispatch(handleNextStep());
    }
  );
}

const setStep7 = (values, candidateId) => (dispatch, getState) => {
  const valuesToData = async (values) => {

    let deliveries = []

    deliveries = await Promise.all(values.mainDeliveries.map(async (item, i) => {
      let delivery = {};
      if (item?.proofUpload) {
        delivery.proof = await upload(item.proofUpload, dispatch);
      }
      delivery = {...delivery,
        name: item?.name ? item.name : null,
        budget: item?.budget ? item.budget : null,
        year: item?.year ? parseInt(item.year) : null,
        id: item?.deliveryId ? `api/deliveries/${item.deliveryId}` : null,
        contractorId: item?.organization ? item.organization : null,
      }
      return delivery;
    }));

    return deliveries;
  }

  return valuesToData(values)
  .then((deliveries) => {

    let currentContractors = formSupplierSelectors.contractorsSelector(getState());
    let data = {
      contractors: []
    };

    values.averageAnnualWorkforce.forEach((item, i) => {

      let contractorId = currentContractors[i].id;
      let contractorDeliveries = deliveries.filter((element) => parseInt(element.contractorId) === parseInt(contractorId));
      let finalContractor = {
        workforceY1: item?.y1Average ? item.y1Average : null,
        executivePercentY1: item?.y1PartStaff ? item.y1PartStaff : null,
        workforceY2: item?.y2Average ? item.y2Average : null,
        executivePercentY2: item?.y2PartStaff ? item.y2PartStaff : null,
        workforceY3: item?.y3Average ? item.y3Average : null,
        executivePercentY3: item?.y3PartStaff ? item.y3PartStaff : null,
        id: `api/contractors/${contractorId}`,
      }
      finalContractor.deliveries = contractorDeliveries;

      data.contractors.push(finalContractor);
    });

    return dispatch(updateCandidate({...data, step: 7}, candidateId)).then(
      success => {
        return dispatch(handleNextStep());
      }
    );
  })
  .catch((err) => {
    console.log((err))
  });

}

const setStep8 = (values, candidateId) => (dispatch, getState) => {
  let currentContractors = formSupplierSelectors.contractorsSelector(getState());
  let data = {
    considerSubcontracting: values?.outSourcing && values.outSourcing === 'yes' ?
    true : false,
    contractors: []
  }

  // Groupe les sous traitants par contractors
  let subcontractingsByContractors = currentContractors.map(element => {
    return {
      contractorId:element.id,
      subcontractings:[]
    }
  })

  values.otherOperators.forEach((item, i) => {
    let contractorId = item?.organization ? item.organization : null;

    let subcontracting = {
      id: item?.interlocutorId ? `api/interlocutors/${item.interlocutorId}` : null,
      name: item?.name ? item.name : null,
      siret: item?.siret ? item.siret : null,
      contractorId: contractorId,
    }

    let contractorIndex = subcontractingsByContractors.findIndex((element) => parseInt(element.contractorId) === parseInt(contractorId));

    if (contractorIndex >= 0 && subcontractingsByContractors[contractorIndex]) {
      subcontractingsByContractors[contractorIndex].subcontractings.push(subcontracting)
    }
  });

  subcontractingsByContractors.forEach((item, i) => {
    let contractorId = item.contractorId;
    data.contractors.push({
      id: `api/contractors/${contractorId}`,
      subcontractingInterlocutors: item.subcontractings
    });
  });

  // Evite d'envoyer un tableau vide qui supprimerai les contractors
  if (data.contractors.length < 1) {
    delete data.contractors;
  }

  return dispatch(updateCandidate({...data, step: 8}, candidateId)).then(
    success => {
      return dispatch(handleNextStep());
    }
  );

}

const setStep9 = (values, candidateId) => async (dispatch, getState) => {
  const valuesToData = async (values) => {

    let presentationHumanProofs = []

    if (values?.proofs && values?.proofs.length > 0) {
      presentationHumanProofs = await Promise.all(values.proofs.map(async (proof, i) => {
        let presentationHumanProof = null;
        if (proof?.proofUpload) {
          presentationHumanProof = await upload(proof.proofUpload, dispatch);
        }
        else if (proof?.proofId) {
          presentationHumanProof = `api/media/${proof.proofId}`
        }
        return presentationHumanProof;
      }));
    }

    return presentationHumanProofs;
  }

  return valuesToData(values).then((presentationHumanProofs) => {
    let data = {
      authorizationSEPA: values?.authorizationSEPA && values.authorizationSEPA === 'yes' ?
      true : false,
      presentationEnvironment: values?.presentationEnvironment ? values.presentationEnvironment : values.presentationEnvironment,
      presentationHuman: values?.presentationHuman ? values.presentationHuman : values.presentationHuman,
      presentationOperative: values?.presentationOperative ? values.presentationOperative : values.presentationOperative,
      presentationHumanProofs: presentationHumanProofs
    }

    return dispatch(updateCandidate({...data, step: 9}, candidateId)).then(
      success => {
        return dispatch(handleNextStep());
      }
    );
  })
  .catch((err) => {
    console.log((err))
  });


}

const setStep10 = (values, candidateId) => (dispatch, getState) => {
  let data = {
    departmentInterlocutors: [],
    identicalOffer: values?.identicalOffer && values.identicalOffer === 'yes' ?
    true : false,
  };

  // Si l'offre est identique pour tous les départements, recherche pour chaque prix de chaque département si le prix a déjà un id (departmentInterlocutorPriceId)
  const allDepartmentInterlocutors = [...values.departments, ...values.secondaryDepartments];
  if (values.identicalOffer === 'yes') {
    allDepartmentInterlocutors.forEach((departmentInterlocutor, i) => {
      let d = {
        'id': departmentInterlocutor?.id ? `api/department_interlocutors/${departmentInterlocutor.id}` : null,
        'prices': []
      }
      values.allDepartments.forEach((article) => {
        let departmentInterlocutorPrice = departmentInterlocutor.articles.find((element) => parseInt(element.articleId) === article.articleId);
        let departmentInterlocutorPriceId = null;
        if (departmentInterlocutorPrice) {
          departmentInterlocutorPriceId = departmentInterlocutorPrice.id;
        }

        // Pas de vérif plus poussé car required sur les inputs en amont
        if (article?.priceA && article?.priceAB && article?.priceB) {
          if ((parseInt(article.priceA) < parseInt(article.priceAB)) || (parseInt(article.priceAB) < parseInt(article.priceB))) {
            // throw new Error(`Le prix de l'article n'est pas cohérent : "${article.name}". Il n’est pas habituel d’avoir un prix unitaire qui augmente plus on achète de quantités, êtes-vous certain de vouloir poursuivre ?`);
          }
        }

        d.prices.push({
          id: departmentInterlocutorPriceId ? `api/department_interlocutor_prices/${departmentInterlocutorPriceId}` : null,
          article: article?.articleId ? `api/articles/${article.articleId}` : null,
          priceA: article?.priceA ? parseInt(article.priceA * 100) : null,
          priceB: article?.priceAB ? parseInt(article.priceAB * 100) : null,
          priceC: article?.priceB ? parseInt(article.priceB * 100) : null,
        })
      });
      data.departmentInterlocutors.push(d);
    });
  }
  else {
    allDepartmentInterlocutors.forEach((departmentInterlocutor, i) => {
      let d = {
        'id': departmentInterlocutor?.id ? `api/department_interlocutors/${departmentInterlocutor.id}` : null,
        'prices': []
      }

      departmentInterlocutor.articles.forEach((article) => {
        // Pas de vérif plus poussé car required sur les inputs en amont
        if (article?.priceA && article?.priceAB && article?.priceB) {
          if ((parseInt(article.priceA) < parseInt(article.priceAB)) || (parseInt(article.priceAB) < parseInt(article.priceB))) {
            // throw new Error(`Le prix de l'article n'est pas cohérent : ${departmentInterlocutor.name} - "${article.name}". Il n’est pas habituel d’avoir un prix unitaire qui augmente plus on achète de quantités, êtes-vous certain de vouloir poursuivre ?`);
          }
        }

        d.prices.push({
          id: article?.id ? `api/department_interlocutor_prices/${article.id}` : null,
          article: article?.articleId ? `api/articles/${article.articleId}` : null,
          priceA: article?.priceA ? parseInt(article.priceA * 100) : null,
          priceB: article?.priceAB ? parseInt(article.priceAB * 100) : null,
          priceC: article?.priceB ? parseInt(article.priceB * 100) : null,
        })
      });
      data.departmentInterlocutors.push(d);
    });
  }

  return dispatch(updateCandidate({...data, step: 10}, candidateId)).then(
    success => {
      return dispatch(handleNextStep());
    }
  );
}

// Madein géré sur l'api
const setStep11 = (values, candidateId) => (dispatch, getState) => {
  let data = {
    acceptDeal: values?.acceptDeal ? values.acceptDeal : false,
    madeIn: values?.madeIn ? values.madeIn : null,
    closeAndGenerate: true,
  };

  return dispatch(updateCandidate({...data, step: 11}, candidateId)).then(
    success => {
      return dispatch(handleNextStep());
    }
  );
}

const setStep12 = (values, candidateId) => (dispatch, getState) => {
  let data = {
    closeAndGenerate: values?.closeAndGenerate ? values.closeAndGenerate : false
  };

  return dispatch(updateCandidate({...data, step: 11}, candidateId));
}

/**
 * Utile pour l'étape des prix
 * Important de mettre l'étape pour ne pas revenir à l'étape d'avant
 */
const updateIdenticalOffer = (isIdenticalOffer, candidateId) => (dispatch, getState) => {

  let data = {
    identicalOffer: isIdenticalOffer
  };
  return dispatch(updateCandidate({...data, step: 9}, candidateId)).then(
    success => {
      return {success:true};
    }
  );
}

/**
 * Vérifie les prix avant de passer à l'étape suivante
 */
const setStep10Alt = (data, candidateId) => (dispatch, getState) => {

  return dispatch(checkCandidatePrices(data)).then(
    success => {
      return dispatch(updateCandidate({step: 10}, candidateId)).then(
        success => {
          return dispatch(handleNextStep());
        }
      );
    }
  );
}

export {
    setStepInprogress,
    setStepIsLoading,
    setStep2,
    setStep3,
    setStep4,
    setStep5,
    setStep6,
    setStep7,
    setStep8,
    setStep9,
    setStep10,
    setStep11,
    setStep12,
    loadCandidate,
    resetCandidate,
    generatePDFCandidate,
    loadCandidateByToken,
    setStep,
    updateCandidate,
    initCandidate,
    getCandidatePrices,
    updateCandidatePrices,
    updateIdenticalOffer,
    setStep10Alt,
    setFilterCandidatePrices,
    resetCandidatePrices,
    saveSubmittedInputs
};
